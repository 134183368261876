<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="organizationGroups"
    item-value="id"
    :item-text="item => (item.header ? item.header : `${item.name} - ${item.description}`)"
    clearable
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} - {{ data.item.description }} </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "OrganizationGroupField",
  props: {
    value: Object,
    status: { type: String, required: false }
  },
  data: () => ({
    organizationGroups: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    ApiService.post("/api/organizationGroups/search?size=500&sort=category,ASC&sort=name,ASC", {
      isEffective: this.status
    }).then(({ data }) => {
      this.organizationGroups = data.content;
      let noCategory = { header: "No category" };
      let last = this.organizationGroups.length > 0 ? this.organizationGroups[0].category : undefined;
      if (!last) {
        this.organizationGroups.unshift(noCategory);
      } else {
        this.organizationGroups.unshift({ header: last });
      }
      for (var i = 0; i < this.organizationGroups.length; i++) {
        if (last != this.organizationGroups[i].category) {
          last = this.organizationGroups[i].category;
          let newone = { header: last };
          this.organizationGroups.splice(i, 0, newone);
        }
      }
    });
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit("change");
      }
    }
  }
};
</script>
