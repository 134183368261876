<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Promotion Library</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 115px; display: inline-block" v-bind="attrs" v-on="on">
            <span style="width: 110px">
              <FiscalYearField
                class="mr-2"
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                v-model="selectedFiscalYear"
                @inputObj="onFiscalYearInput()"
                :client="selectedClient"
                flat
                solo-inverted
                hide-details
                use-store
              ></FiscalYearField>
            </span>
          </div>
        </template>
        Fiscal Year
      </v-tooltip>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn small fab class="mr-2" v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  v-model="filterByEffective"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <CountriesField
                  dense
                  v-model="filterByCountries"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Countries')"
                  :program="selectedProgram"
                  @input="onSubmit"
                ></CountriesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionTypesField
                  dense
                  v-model="filterByPromotionTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Promotion Types')"
                  @input="onSubmit"
                ></PromotionTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ClaimTypesField
                  dense
                  v-model="filterByClaimTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Claim Types')"
                  @input="onSubmit"
                ></ClaimTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantTypeField
                  dense
                  v-model="filterByParticipantType"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Participant Types')"
                  @input="onSubmit"
                ></ParticipantTypeField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantGroupField
                  dense
                  v-model="filterByParticipantGroup"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Participant Group')"
                  @input="onSubmit"
                ></ParticipantGroupField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationTypeField
                  dense
                  v-model="filterByOrganizationType"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Organization Type')"
                  @input="onSubmit"
                ></OrganizationTypeField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationGroupField
                  dense
                  v-model="filterByOrganizationGroup"
                  :label="filterByOrganizationGroupLabel"
                  @input="onSubmit"
                ></OrganizationGroupField>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small fab class="mr-2" color="secondary" v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small fab class="mr-2" v-on="{ ...onTooltip }" :to="{ name: 'salesnews', params: { id: 0 } }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Promotion</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.promotion.promotionKey="{ item }">
          <router-link :to="{ name: 'salesnews', params: { id: item.promotion.id } }">
            {{ item.promotion.promotionKey }}
          </router-link>
        </template>
        <template v-slot:item.promotion.name="{ item }">
          {{ item.promotion.name }}
          <v-tooltip bottom v-if="!item.valid">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-alert-circle
              </v-icon>
            </template>
            <span>Required Information is Missing! </span>
          </v-tooltip>
        </template>
        <template v-slot:item.closestSubmittableEffectiveDate="{ item }">
          <span v-if="item.closestSubmittableEffectiveDate">
            {{ item.closestSubmittableEffectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
          >
        </template>
        <template v-slot:item.closestSubmittableExpirationDate="{ item }">
          <span v-if="item.closestSubmittableExpirationDate">
            {{ item.closestSubmittableExpirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
          >
        </template>
        <template v-slot:item.closestSubmittableSubmissionDeadlineDate="{ item }">
          <span v-if="item.closestSubmittableSubmissionDeadlineDate">
            {{ item.closestSubmittableSubmissionDeadlineDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
          >
        </template>
        <template v-slot:item.promotion.submissionsActive="{ item }">
          <v-chip color="primary" v-if="item.promotion.submissionsActive">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
        <template v-slot:item.promotion.publishDate="{ item }">
          {{ item.promotion.publishDate | formatDateFromNow }}
        </template>
        <template v-slot:item.promotion.updatedDate="{ item }">
          {{ item.promotion.updatedDate | formatDateFromNow }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CountriesField from "../../gapp-components/components/fields/CountriesField.vue";
import PromotionTypesField from "../../gapp-components/components/fields/PromotionTypesField.vue";
import ClaimTypesField from "../../gapp-components/components/fields/ClaimTypesField.vue";
import ParticipantTypeField from "../../gapp-components/components/fields/ParticipantTypeField.vue";
import ParticipantGroupField from "../../gapp-components/components/fields/ParticipantGroupField.vue";
import OrganizationTypeField from "../../gapp-components/components/fields/OrganizationTypeField.vue";
import OrganizationGroupField from "../../gapp-components/components/fields/OrganizationGroupField.vue";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField";

export default {
  name: "SalesNewsLibrary",
  metaInfo: {
    title: "Promotion Library"
  },
  components: {
    FiscalYearField,
    OrganizationGroupField,
    OrganizationTypeField,
    ParticipantGroupField,
    ParticipantTypeField,
    ClaimTypesField,
    PromotionTypesField,
    CountriesField
  },
  data() {
    return {
      now: moment(),
      isMounted: false,
      search: "",
      filterByEffective: undefined,
      filterByCountries: [],
      filterByPromotionTypes: [],
      filterByClaimTypes: [],
      filterByOrganizationType: undefined,
      filterByOrganizationGroup: undefined,
      filterByParticipantType: undefined,
      filterByParticipantGroup: undefined,
      selectedFiscalYear: undefined,
      loading: false,
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "promotion.promotionKey",
          text: "Number",
          sortable: true
        },
        {
          value: "promotion.name",
          text: "Title",
          sortable: true
        },
        {
          value: "promotion.promotionType.name",
          text: "Type",
          sortable: true
        },
        {
          value: "promotion.country.name",
          text: "Country",
          align: "center",
          sortable: true
        },
        {
          value: "closestSubmittableEffectiveDate",
          text: "Start Date",
          sortable: true,
          align: "center"
        },
        {
          value: "closestSubmittableExpirationDate",
          text: "End Date",
          sortable: true,
          align: "center"
        },
        {
          value: "closestSubmittableSubmissionDeadlineDate",
          text: "Claiming Deadline",
          sortable: true,
          align: "center"
        },
        {
          value: "promotion.submissionsActive",
          text: "Status",
          align: "center"
        },
        {
          value: "promotion.publishDate",
          text: "Published?"
        },
        {
          value: "promotion.updatedDate",
          text: "Last Updated",
          sortable: true
        }
      ],
      total: 0,
      options: {
        sortBy: ["updatedDate"],
        sortDesc: [true],
        itemsPerPage: 10
      },

      effectiveItems: [
        { text: "Active", value: true },
        { text: "Inactive", value: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onFiscalYearInput() {
      // Needed because the mounting of the fiscal year field is triggering a 2nd call
      if (this.isMounted) {
        this.getData();
      }
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByEffective = undefined;
      this.filterByCountries = [];
      this.filterByPromotionTypes = [];
      this.filterByClaimTypes = [];
      this.filterByOrganizationType = undefined;
      this.filterByOrganizationGroup = undefined;
      this.filterByParticipantType = undefined;
      this.filterByParticipantGroup = undefined;
      this.onSubmit();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {
        isEffective: "All"
      };

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.filterByEffective != undefined) {
        filters.isSubmissionsEffective = this.filterByEffective;
      }

      if (this.filterByCountries && this.filterByCountries.length > 0) {
        filters.countries = this.filterByCountries;
      }
      if (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) {
        filters.promotionTypes = this.filterByPromotionTypes.map(promotionType => {
          return {
            id: promotionType.id
          };
        });
      }

      if (this.filterByClaimTypes && this.filterByClaimTypes.length > 0) {
        filters.claimTypes = this.filterByClaimTypes.map(claimType => {
          return {
            id: claimType.id
          };
        });
      }

      if (this.filterByParticipantType) {
        filters.participantType = { id: this.filterByParticipantType.id };
      }
      if (this.filterByParticipantGroup) {
        filters.participantGroup = { id: this.filterByParticipantGroup.id };
      }
      if (this.filterByOrganizationType) {
        filters.organizationType = { id: this.filterByOrganizationType.id };
      }
      if (this.filterByOrganizationGroup) {
        filters.organizationGroup = { id: this.filterByOrganizationGroup.id };
      }
      if (this.selectedFiscalYear) {
        filters.fiscalYear = this.selectedFiscalYear;
      }

      this.$api
        .post(
          "/api/promotions/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.items = this.$promotion.findAndAssignClosestDates(this.items);
          this.total = data.totalElements;
        })
        .finally(() => {
          this.isMounted = true;
          this.loading = false;
        });
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },
  mounted() {
    if (this.$route.query.active != undefined) {
      this.filterByEffective = parseInt(this.$route.query.active) == 1;
    }

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Promotion Library" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient", "selectedParticipant"]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        this.filterByEffective != undefined ||
        (this.filterByCountries && this.filterByCountries.length > 0) ||
        (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) ||
        (this.filterByClaimTypes && this.filterByClaimTypes.length > 0) ||
        this.filterByParticipantType != undefined ||
        this.filterByParticipantGroup != undefined ||
        this.filterByOrganizationType != undefined ||
        this.filterByOrganizationGroup != undefined;
      return check;
    },
    filterByOrganizationGroupLabel() {
      return "Filter by " + this.$i18n.translate("Organization Group");
    }
  }
};
</script>
