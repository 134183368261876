<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="participantTypes"
    item-value="id"
    :item-text="renderItem"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ParticipantTypeField",
  props: {
    value: Object,
    program: {
      type: Object,
      default: null,
      required: false
    },
    showKey: { type: Boolean, default: false },
    status: { type: String, required: false }
  },
  data: () => ({
    participantTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    renderItem(item) {
      return (this.showKey ? item.participantTypeKey + " - " : "") + item.name;
    }
  },
  mounted() {
    let filters = {};
    if (this.program && this.program.id) {
      filters.program = { id: this.program.id };
    }

    filters.isEffective = this.status;

    ApiService.post("/api/participantTypes/search?sort=name&size=100", filters).then(({ data }) => {
      this.participantTypes = data.content;
    });
  }
};
</script>
